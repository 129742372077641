
(function () {
  jQuery(document).ready(function($){

    // Functions ---------------------------------------------------------------

    // Read Ulr from iput
    function readURL(input) {
      if (input.files && input.files[0]) {
          var reader = new FileReader();
          reader.onload = function(e) {
              $('.profile_img').css('background-image', 'url('+e.target.result +')');
              $('.profile_img').hide();
              $('.profile_img').fadeIn(650);
          }
          reader.readAsDataURL(input.files[0]);
      }
    }

    $(".profile-pic-file-input").change(function() {
        readURL(this);
        $('.profile_picture').addClass('update');
    });

    $('.nav-toggle').on('click', function(e) {
      $(this).toggleClass('nav-open');
      $('.nav_mobile_wrapper').toggleClass('open');
    });
    // Loader ------------------------------------------------------------------
    jQuery(document).ready(function() {
      setTimeout(function(){
        $('.site_loader').remove();
      }, 300);
    });


    // Home Page Slider---------------------------------------------------------

    // English
    $('.hero_slider').slick({
      infinite: true,
      autoplay: true,
      slidesToScroll: 1,
      slidesToShow: 1,
      prevArrow: false,
      nextArrow: false,
      fade: true,
      speed: 2000,
      cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
      touchThreshold: 100
    });

    // Arabic
    $('.hero_slider_rtl').slick({
      rtl: true,
      infinite: true,
      autoplay: true,
      slidesToScroll: 1,
      slidesToShow: 1,
      prevArrow: false,
      nextArrow: false,
      fade: true,
      speed: 2000,
      cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
      touchThreshold: 100
    });

    // English
    $('.insp_photos').slick({
      arrows: true,
      centerPadding: "0px",
      dots: true,
      autoplay: true,
      speed: 1200,
      slidesToShow: 4,
      slidesToScroll: 4,
      infinite: true,
      prevArrow: false,
      nextArrow: false,
        responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ]
    });

    // Arabic
    $('.insp_photos_rtl').slick({
      arrows: true,
      centerPadding: "0px",
      dots: true,
      rtl: true,
      autoplay: true,
      speed: 1200,
      slidesToShow: 4,
      slidesToScroll: 4,
      infinite: true,
      prevArrow: false,
      nextArrow: false,
        responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ]
    });

    // English
    $('.workshop_cards').slick({
      slidesToScroll: 3,
      slidesToShow: 3,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    });

    // Arabic
    $('.workshop_cards_rtl').slick({
      slidesToScroll: 3,
      slidesToShow: 3,
      rtl: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    });

    // Awards awards carousel

    $('.awards_carousel').slick({
      slidesToScroll: 1,
      slidesToShow: 3,
      lazyLoad: 'ondemand',
      centerMode: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            dots: false
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    });

    $('.awards_carousel_rtl').slick({
      slidesToScroll: 1,
      slidesToShow: 3,
      lazyLoad: 'ondemand',
      rtl: true,
      centerMode: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            dots: false
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    });
  });

  $('.site_general_form').validate();

  $('.update_password').validate();

  $('.newsletter_form').validate();


  $('#googleMap').on('click', function() {
        $('.google_maps').addClass('show');
  });

  $('#closeMap').on('click', function() {
      $('.google_maps').removeClass('show');
  });

  //  Upload Video
  jQuery(document).ready(function($){
    $('.item_link').on('click', function(){
      $('.upload_selection').addClass('slide_left');

      if($(this).hasClass('video')) {
        $('.upload_section').addClass('slide_left');
        $('.upload_item.video').addClass('show');
        $('.select_btn').addClass('video');
      } else {
        $('.upload_item').removeClass('show');
        $('.upload_section').addClass('slide_left');
        $('.upload_item.images').addClass('show');
      }

      $('.upload_section .uploader').attr('name', '');
      $('.upload_section .show .uploader').attr('name', 'mediaList');

      var height = $('.upload_section').outerHeight();
      $(".uploading_files").css("height", height);
    });

    $('.select_btn').on('click', function(){
      $('.upload_section').removeClass('slide_left');
      setTimeout(function(){$('.upload_selection').removeClass('slide_left');}, 200);
      $('.upload_item').removeClass('show');
      var height = $('.upload_selection').outerHeight();
      $(".uploading_files").css("height", height);
    });

    $('#uploadVideo').change(function(){
      $('.upload_item_wrapper').addClass("hide");
      // Create new FileReader as a variable
      var reader = new FileReader();

      // Onload Function will run after video has loaded
      reader.onload = function(file){
        var fileContent = file.target.result;
        $('.upload_item').find('video').remove();
        $('.upload_item').append('<video src="' + fileContent + '" width="auto" height="315px" controls></video>');
      }

      reader.readAsDataURL(this.files[0]);
    });

    $('#uploadImage').on('change', function(e) {
      return multiPhotoDisplay(this);
    });
  });
  // Close Alert
  $('.close').on('click', function (e) { $('.alert-success').removeClass('alert-success'); $('.alert-error').removeClass('alert-error'); });

  // Contact Page

  $('.circle.spin').on('click', function(){
    $('.send_email').click();
  });

  $('.mobile_filter').on('click', function(){
    $('.opt_wrapper').toggleClass('open');
  });

  $('#changeProfilePic').on('click', function(){
    $('.profile-pic-file-input').click();
  });


  // Awards page

  $('.swiper_pagination .group_item').on('click', function(){
    $('.swiper_pagination .group_item').removeClass('active');
    $(this).addClass('active');
  });


  // Gallry page
  $('.filter_opt .opt_item').on('click', function(){
    alert('sdfsd');
    var year = $(this).html();
    console.log(year);
    $('#galleryYear').html(year);
    $('.mobile_filter').html(year);
  });

  $(".filter_dropdown").click(function(e){
    e.stopPropagation();
  });

  $(document).click(function(){
    $(".dropdown_opt").removeClass('open');
  });

  // Account Page
  $('.edit_passport').on('click', function(){
    $('#updatePassport').click();
  });

  var readURL = function(input) {
        if (input.files && input.files[0]) {
            var reader = new FileReader();

            reader.onload = function (e) {
                $('.passport_photo').attr('src', e.target.result);
            }

            reader.readAsDataURL(input.files[0]);
        }
    }

  $('.dropdown_heading').on('click', function(){
    $('.dropdown_opt').toggleClass('open');
  });

  $('.view_btn').on('click', function(){
    $(this).parent().parent().find('.item_img a').trigger('click');
  });


  $(window).on('load', function () {
    $('.opt_item').on('click', function () {
      $('.dropdown_opt').removeClass('open');
    });

    $('.thumbnail_gallery').iLightBox();

    setTimeout(function() {
        $('.site_nav').removeClass('wow fadeInDown');
        $('.site_nav').removeAttr("style");
    }, 200);


    $('.uploader').on('change', function(e) {
      var apending = $(this).parent().find(".addmore_files"),
      apendingto = $(this).parent().find(".files .row:last-child");

      setTimeout(function() {
        apending.addClass("show");
        apending.appendTo(apendingto);
      }, 100);
    });
  });

  new WOW().init();

  // $(window).resize(function(){
  //   $('.resize')[0].slick.refresh();
  // });

  var slickEnabled = true;

  $('.swiper_pagination_group .item_txt').on('click', function (e) {
      if (slickEnabled) {
          $('.awards_carousel').unslick();
          $(this).text('Slick');
          slickEnabled = false;
      } else {
          $('.awards_carousel').slick(slickOpts);
          $(this).text('Unslick');
          slickEnabled = true;
      }
  });
  var availableTags = ["ActionScript", "AppleScript", "Asp", "BASIC", "C", "C++", "Clojure"];
})();
